
import { mapMutations } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    menuType: {
      type: String,
      default: "top",
    },
  },
  data() {
    return {
      activeItemImage: null,
      activeMenu: null,
      timeout: null,
    };
  },
  computed: {
    menuOpened() {
      return !!(this.activeMenu && this.activeMenu.children?.length);
    },
  },
  watch: {
    "$route.fullPath"() {
      this.setActiveMenu(null);
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.setDesktopMenu(null);
    });
  },
  methods: {
    ...mapMutations({
      setDesktopMenu: "general/SET_DESKTOP_MENU",
    }),
    onMouseLeave() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setActiveMenu(null);
      }, 350);
    },
    setActiveMenu(item) {
      clearTimeout(this.timeout);
      this.activeMenu = item;
    },
    getComponentType(link) {
      if (link.is_title) {
        return "div";
      } else if (link.url.includes("btn-id-")) {
        return "button";
      } else {
        return "UiButton";
      }
    },
  },
};
