import { render, staticRenderFns } from "./TopMenu.vue?vue&type=template&id=fca398c6&scoped=true&"
import script from "./TopMenu.vue?vue&type=script&lang=js&"
export * from "./TopMenu.vue?vue&type=script&lang=js&"
import style0 from "./TopMenu.vue?vue&type=style&index=0&id=fca398c6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fca398c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLazyImg: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiLazyImg.vue').default,UiIcon: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiIcon.vue').default})
