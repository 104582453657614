
import { toTree } from "~/utils";
export default {
  name: "HeaderSeoNav",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getComponentType(link) {
      if (link.is_title) {
        return "div";
      } else if (link.url.includes("btn-id-")) {
        return "button";
      } else {
        return "UiButton";
      }
    },
  },
};
